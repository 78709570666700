<template>
  <section>
    <b-card>
      <b-card-title>Transações</b-card-title>

      <TablePagination
        v-model="items"
        url="/user/orders"
        :per-page="10"
        default-filter-by="created_at"
        :fields="fields"
      >
        <template v-slot:type="data">
          <span v-if="data.items.type === 1">SMS</span>
          <span v-if="data.items.type === 2">Seguidor</span>
          <span v-if="data.items.type === 3">Conta</span>
          <span v-if="data.items.type === 4">Pack de Fotos</span>
          <span v-if="data.items.type === 5">VPS</span>
          <span v-if="data.items.type === 6">BOT</span>
          <span v-if="data.items.type === 7">Controle</span>
          <span v-if="data.items.type === 8">Sorteio</span>
        </template>

        <template v-slot:name="data">
          <img
            class="rounded-lg"
            :src="`${$api_host}/storage/assets/${data.items.image}`"
            width="35px"
          > &nbsp;
          <span v-html="data.items.name" />
        </template>

        <template v-slot:amount="data">
          <span v-if="data.items.type === 2">
            {{ data.items.amount | currency2 }}
          </span>
          <span v-else>
            {{ data.items.amount | currency }}
          </span>
        </template>

        <template v-slot:created_at="data">
          {{ data.items.created_at | date }}
        </template>

        <template v-slot:quantity="data">
          {{ data.items.quantity }}
        </template>

        <template v-slot:status="data">
          <b-button
            size="sm"
            :variant="getStatus(data.items.status).color"
          >
            {{ getStatus(data.items.status).message }}
          </b-button>
        </template>
      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import { BCard, BCardTitle, BButton } from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 4)
    },
    currency2 (val) {
      return currencyPrecision(val, 2, 6)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    items: [],
    fields: [
      {
        key: 'type',
        label: 'tipo'
      },
      {
        key: 'name',
        label: 'Produto',
        sortable: true
      },
      {
        key: 'amount',
        label: 'total',
        sortable: true
      },
      {
        key: 'quantity',
        label: 'quantidade',
        sortable: true
      },
      {
        key: 'created_at',
        label: 'Data de realização',
        sortable: true
      },
      {
        key: 'status',
        label: 'status'
      }
    ]
  }),
  methods: {
    getStatus (status) {
      if (status === 1) {
        return {
          color: 'relief-primary',
          message: 'Pendente'
        }
      }

      if (status === 2) {
        return {
          color: 'relief-success',
          message: 'Aprovado'
        }
      }

      if (status === 3) {
        return {
          color: 'relief-danger',
          message: 'Cancelado'
        }
      }
    }
  }
}
</script>
